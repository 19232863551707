@import '../../theme/variables';

.container {
  position: relative;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
}

.clickable {
  cursor: pointer;
}

.iconContainer {
  position: relative;
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.notificationIcon {
  position: relative;
  display: flex;
  align-items: center;

  .notification {
    position: absolute;
    flex-shrink: 0;
    top: -15%;
    right: 0;
    font-size: 10px;
    color: $neutral-white;
    background-color: $notification;
    line-height: 10px;
    padding: 2px 4px 2px 4px;
    border-radius: 10px;
    min-width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $neutral-white;
    transform: translate(50%, 0);
  }
}
